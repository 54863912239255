@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');

:root {
  --primary: rgb(97,194,200);
  --secondary: rgb(29,36,69);
  --videowidth: 800px;
  --iframeedge: 8rem;
  --containerwidth: 100vw;
}
body *{
  box-sizing: border-box;
}
body .container{
  font-size: 16px;
  /* max-width: 1200px; */
  margin: 0 auto;
  height: 100vh;
  color: var(--secondary);
  font-family: 'Nunito', sans-serif;
}
header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4em 0rem 4em;
}
.container h1{
  font-size: 3.5rem;
  text-align: center;
  /* width: 70%; */
  margin: 0 auto;
  line-height: 3.75rem;
}
.container h3{
  text-align: center;
  padding: .5rem 0 0rem 0;
  margin: 0;
}
.container .info{
  text-align: center;
  margin: 0 auto;
  padding: 1.5rem 0 3rem 0;
}
.if-btn{
  text-transform: uppercase;
  color: #FFF;
  border: none;
  padding: 1rem 0;
  border: none;
  background-color: var(--primary);
  border-radius: 8px;
  font-weight: bold;
  width: 14rem;
  cursor: pointer;
}
.if-btn a{
  color: white;
  text-decoration: none;
}
.web-head .info{
  padding-top: 0;
}
.web-head h1{
  margin-top: 1em;
}
.web-head .subheading{
  text-align: center;
  padding: 1.5rem 0 .5rem 0;
}
header .logo img{
  width: 200px;
}
.video-section{
  height: calc((var(--videowidth) / 1.778) + 5rem);
}
.video-section > div{
  position: absolute;
  left: 0;
  right: 0;
  background: rgb(41 148 150);
  height: calc((var(--videowidth) / 1.778) - 3rem);
  display: flex;
  justify-content: center;
  margin-top: var(--iframeedge);

}
.video-section > div iframe{
  position: absolute;
  top: calc(var(--iframeedge) * -1);
  width: var(--videowidth);
  height: calc(var(--videowidth) / 1.778);
  border: none;
  box-shadow: 0 15px 70px rgb(0 0 0 / 30%);
}
.organization-section{
  padding: 84px 4em 0 4em;
  display: flex;
  align-items: center;
}
.organization-section h1{
  text-align: start;
  margin: 0;
  width: 100%;
  margin-bottom: 42px;
}
.organization-section > div{
  width: 50%;
  padding: 0 4rem;
}
.organization-section > div img{
  width: 100%;
}
.organization-section > div .stats{
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}
.organization-section > div .stats .count{
  font-weight: bold;
  font-size: 2.5rem;
}
.coach-section{
  padding: 84px 0;
}
.coach-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.coach-list .coach{
  width: 20%;
  margin-right: 16px;
  margin-bottom: 16px;
  height: 120px;
  /* background: rgb(216 216 216); */
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #222e5011;
  justify-content: center;
}
.coach-list .coach img{
  height: 4em;
  max-width: 90%;
}
.coach-list .coach.uber img{
  height: 2.25em;
}
.coach-list .coach.paypal img{
  height: 3em;
}
.coach-list .coach.amazon img{
  height: 4.5rem;
}
.book-memo,
.linkedin-section,
.testimonial-section{
  /* position: relative; */
}
.book-memo>div{
  color: white;
  background: rgb(34 46 80);
  padding: 56px 0 0 0;
}
.linkedin-content{
  background: rgb(9 118 180);
  color: white;
  padding: 6rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.linkedin-content > div:nth-child(2){
  width: 60%;
}
.linkedin-content > div:nth-child(1){
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.linkedin-content h1{
  margin: 0;
  text-align: start;
}
.linkedin-content .info{
  text-align: start;
  font-weight: bold;
  font-size: 1.375rem;
  padding-bottom: 1.5rem;
}
.linkedin-content .info:nth-child(2){

}
.linkedin-content img{
  margin-top: -56px;
}
footer{
  background-color: rgb(28 28 28);
  color: white;
}
footer>div{
  width: 100%;
  display: flex;
  padding: 4.5rem 9rem 5rem 9rem;
}
footer .left-section{
  width: 35%;
}
footer .left-section .title{
  font-size: 3rem;
  font-weight: bold;
}
footer .left-section .info{
  text-align: start;
  margin-bottom: 4.5rem;
}
footer .right-section{
  width: 65%;
  display: flex;
  font-size: 1.125rem;
}
footer .right-section > div .title{
  font-weight: bold;
  margin-bottom: 1.125rem;
}
footer .right-section > div.address{
  max-width: 40%;
  padding: 0 3rem 0 2rem;
}
footer .right-section > div .links > div{
  margin-bottom: 1.125rem;
}
.book-socialize{
  flex-grow: 2;
}
.book-socialize .if-btn{
  margin-bottom: 4rem;
}
.social-links img{
  width: 2rem;
}
.social-links > div {
  cursor: pointer;
}
.social-links{
  display: flex;
}
.social-links>div{
  margin-right: .5rem;
}
.social-links .linkedin img{
  width: 1rem;
}
.social-links .linkedin,
.social-links .slack{
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgb(9 118 180);
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-links .slack{
  background: rgb(206 30 91);
}
.testimonial-section{
  padding: 8rem 0 4rem 0;
}
.testimonial-section img{
  height: 9rem;
  padding: 1rem;
  background: white;
  box-shadow: 1px 1px 19px #f1f1f1;
  border-radius: 8px;
}
.testimonial-card .name{
  margin-top: 2rem;
}
.testimonial-card{
  padding-top: 8rem !important;
  display: flex;
  width: 75%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-image: url(./assets/quote.svg);
  background-position: 13rem 3rem;
  background-size: 10rem;
}
.testimonial-card > div{
  margin-left: 2.25rem;
  display: flex;
  flex-direction: column;
}
.testimonial-card > div .message{
  flex: 1 1 auto;
}
.container{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.container {
  max-width: var(--containerwidth);
  overflow-x: hidden;
}
.book-memo,
.linkedin-section,
.testimonial-section,
footer{
  width: var(--containerwidth);
  margin: 0 auto;
  /* margin: 0 calc((100vw - var(--containerwidth)) * -.5); */
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.verified-container {
  display: flex;
  flex-direction: column;
}
.verified-container .center{
  background: white;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4rem;
}
.verified-container .verified-page{
  width: 50%;
  padding: 3rem 4rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  text-align: center;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 1px;
}
.verified-container .verified-page img{
  margin: 16px 0;
}

.right-section .company .links > div{
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  :root{
    --containerwidth: 100vw;
  }
}

@media screen and (min-width: 1400px) {
  :root{
    --containerwidth: 100vw;
  }
}
@media screen and (min-width: 1600px) {
  :root{
    --containerwidth: 1500px;
  }
}
@media screen and (min-width: 1900px) {
  :root{
    --containerwidth: 1800px;
  }
}
@media screen and (max-width: 820px) {
  .video-section > div iframe{
    width: calc(100vw - 4rem);
    height: calc((100vw - 4rem) / 1.778);
  }
  .video-section > div{
    height: calc(((100vw - 4rem) / 1.778) - 3rem)
  }
  .video-section{
    height: calc(((100vw - 4rem) / 1.778) + 5rem)
  }
  .organization-section{
    flex-direction: column;
  }
  .organization-section > div{
    width: calc(100%);
  }
  footer>div{
    flex-direction: column;
    padding: 4.5rem 2rem;
  }
  footer .right-section{
    width: 100%;
    flex-direction: column;
  }
  footer .right-section>div{
    padding: 0 !important;
    margin-top: 2rem;
    width: 100%;
    max-width: 100% !important;
  }
  footer .left-section .info{
    margin-bottom: 0;
    padding-bottom: 1rem!important;
  }
  footer .left-section{
    width: 100%;
  }
  .organization-section > div .stats .count{
    font-size: 2rem;
  }
  .verified-container .verified-page{
    width: 90%;
    padding: 2rem 2rem;
    font-size: 18px;
  }
}
@media only screen and (max-width: 600px) {
  header .logo img {
    height: 2rem;
    width: auto;
  }
  .if-btn{
    padding: 1rem 0;
    width: 10rem;
  }
  header{
    padding: 2rem 1rem;
  }
  .logo{
    display: flex;
    align-items: center;
  }
  .web-head{
    width: 90%;
    margin: 0 auto; 
  }
  .container h1{
    font-size: 2.5rem;
    line-height: 2.625rem;
    margin: 0;
  }
  .organization-section > div{
    padding: 0;
  }
  .organization-section h1{
    margin-bottom: 2rem;
  }
  .organization-section{
    padding: 84px 2em 0 2em;
  }
  .organization-section > div img{
    margin-top: 2rem;
  }
  .coach-list .coach{
    width: 80%;
  }
  .coach-section{
    padding: 84px 2em 84px 2em;
  }
  .linkedin-content{
    flex-direction: column;
  }
  .linkedin-content > div:nth-child(2){
    width: 80%;
    margin-top: 2rem;
  }
  .linkedin-content h1,
  .linkedin-content .info{
    text-align: center;
  }
  .linkedin-content .btn{
    display: flex;
    justify-content: center;
  }
  .testimonial-section{
    padding: 4rem 0 2rem 0
  }
  .testimonial-card{
    background-position: 1.5rem 7rem;
    background-size: 7rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem !important;
  }
  .testimonial-card > div{
    margin: 1rem 2rem 0 2rem;
  }
  .testimonial-card img{
    box-shadow: none;
    width: 250px;
    padding: 0;
    margin: 0 auto;
    height: auto;
    z-index: -1;
  }
  .testimonial-section h1{
    width: 90%;
    margin: 0 auto;
  }
  .testimonial-card .name{
    margin-top: 2rem;
    font-size: 15px;
  }
  .book-memo>div{
    padding: 56px 1rem 0 1rem;
  }
  .organization-section > div .stats .count{
    font-size: 2rem;
  }
  .verified-container .verified-page{
    width: 90%;
    padding: 2rem 2rem;
    font-size: 18px;
  }
}
@media only screen and (max-width: 400px) {
  .if-btn{
    width: 8rem;
    padding: .8rem;
  }
}


